<template>
  <v-container class="pa-0">
    <v-row class="d-flex align-center element-border pb-2" dense>
      <v-col>
        <v-container class="pa-0">
          <v-row align="center" class="mt-2">
            <v-col
              :data-testid="`hub-profile-employees-list-item-${index}-name`"
              cols="auto"
              class="subtitle-text-2"
            >
              {{ employee.firstName }} {{ employee.lastName }}
            </v-col>
            <v-col cols="auto" class="px-0" v-if="employee.status">
              <StatusBadge
                :data-testid="`employee-list-item-${index}-company-badge`"
                :text="statusBadgeText(employee.status)"
                :color="helpers.getStatusBadgeColor(employee.status)"
                :text-color="helpers.getStatusTextColor(employee.status)"
                class="my-1 mr-2 caption-3"
              />
            </v-col>
          </v-row>
          <v-row align="start" class="mt-0 d-flex">
            <v-col
              v-if="employee.hubRole"
              cols="auto"
              class="d-flex align-center"
            >
              <img
                :data-testid="`hub-profile-employees-list-item-${index}-start-outline-icon`"
                :src="StarOutlineIconBlue"
                class="mr-2"
              />
              <p
                :data-testid="`hub-profile-employees-list-item-${index}-hub-role`"
                class="mb-0 body-text-4 blueDark--text"
              >
                {{ getEmployeeHubRole(employee.hubRole) || '-' }}
              </p>
            </v-col>
            <v-col cols="auto" class="d-flex align-center">
              <img
                :data-testid="`hub-profile-employees-list-item-${index}-medical-bag-icon`"
                :src="MedicalBagIcon"
                class="mr-2"
              />
              <p
                :data-testid="`hub-profile-employees-list-item-${index}-job-title`"
                class="mb-0 body-text-4 greyDark--text"
              >
                {{ getQualificationType(employee.qualificationType) || '-' }}
              </p>
            </v-col>
            <v-col
              v-if="displayAdditionalTraining"
              cols="auto"
              class="d-flex align-center"
            >
              <img
                :data-testid="`hub-profile-employees-list-item-${index}-plus-outline-icon`"
                :src="PlusOutlineIcon"
                class="mr-2"
              />
              <p
                :data-testid="`hub-profile-employees-list-item-${index}-additional-training`"
                class="mb-0 body-text-4 greyDark--text"
              >
                {{ additionalTrainingForDisplay }}
              </p>
            </v-col>
            <v-col cols="auto" class="d-flex align-center">
              <img
                :data-testid="`hub-profile-employees-list-item-${index}-clock-icon`"
                :src="ClockIcon"
                class="mr-2"
              />
              <p
                :data-testid="`hub-profile-employees-list-item-${index}-working-hours`"
                class="mb-0 body-text-4 greyDark--text"
              >
                {{ employee.weeklyWorkingHours || '-' }}
              </p>
            </v-col>
            <v-col cols="auto" class="d-flex align-center">
              <img
                :data-testid="`hub-profile-employees-list-item-${index}-start-icon`"
                :src="StartIcon"
                class="mr-2"
              />
              <p
                :data-testid="`hub-profile-employees-list-item-${index}-start-date`"
                class="mb-0 body-text-4 greyDark--text"
              >
                {{ helpers.formatDate(employee.startDateAtKenbi) || '-' }}
              </p>
            </v-col>
            <v-col cols="auto" class="d-flex align-center">
              <img
                :data-testid="`hub-profile-employees-list-item-${index}-fingerprint-icon`"
                :src="FingerprintIcon"
                class="mr-2"
              />
              <p
                :data-testid="`hub-profile-employees-list-item-${index}-employee-number`"
                class="mb-0 body-text-4 greyDark--text"
              >
                {{ employee.employeeNumber || '-' }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="auto" class="px-0">
        <IconButton
          :data-testid="`hub-profile-employees-list-item-${index}-chevron-btn`"
          class="icon-btn"
          icon="mdi-chevron-right"
          :disabled="!userHasPermissionToReadProfile"
          icon-size="28"
          :border="false"
          :to="{
            name: 'EmployeeProfileMenu',
            params: { employeeId: employee.id },
          }"
          @contextmenu.native="recordId(employee.id)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import IconButton from '@/components/shared/customComponents/buttons/icon-button.vue';
import FingerprintIcon from '@/assets/icons/fingerprint-icon.svg';
import ClockIcon from '@/assets/icons/clock-icon.svg';
import MedicalBagIcon from '@/assets/icons/medical-bag-icon.svg';
import StartIcon from '@/assets/icons/start-icon.svg';
import PlusOutlineIcon from '@/assets/icons/plus-outline-icon.svg';
import StarOutlineIconBlue from '@/assets/icons/star-outline-icon-blue.svg';
import StatusBadge from '@/components/shared/customComponents/alertsBadges/status-badge.vue';

import helpers from '@/helpers/helpers';
import languages from '../../locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { computed, defineProps } from 'vue';

const { canUser } = usePermissions();
const { tm } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  employee: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
});

// --- Methods ---
const getQualificationType = (jobTitle) => {
  return helpers.getTranslationById(
    tm('Action_.selectQualificationType'),
    jobTitle
  );
};

const statusBadgeText = (status) => {
  return tm(`Action_.selectEmployeeStatusTypes[${status}]`);
};

const getEmployeeHubRole = (hubRole) => {
  return tm(`Action_.selectHubRoles[${hubRole}]`);
};

const recordId = (id) => {
  localStorage.setItem('employeeId', id);
};

// --- Computed ---
const userHasPermissionToReadProfile = computed(() => {
  return canUser([
    'People.Employees.ReadAdditionalInfo',
    'People.Employees.ReadBank',
    'People.Employees.ReadCar',
    'People.Employees.ReadContract',
    'People.Employees.ReadDocuments',
    'People.Employees.ReadDownloads',
    'People.Employees.ReadMaster',
    'People.Employees.ReadTax',
  ]);
});

const filteredAdditionalTraining = computed(() => {
  return props.employee.additionalTraining.filter(({ Value }) => Value !== 0);
});

const additionalTrainingForDisplay = computed(() => {
  const selectedTrainings = filteredAdditionalTraining.value
    .filter((additionalTraining) => additionalTraining.Value)
    .map((additionalTraining) =>
      tm(`Action_.selectAdditionalTrainingTypes[${additionalTraining.Value}]`)
    );

  return selectedTrainings.join(', ');
});

const displayAdditionalTraining = computed(() => {
  return (
    filteredAdditionalTraining.value &&
    filteredAdditionalTraining.value.length !== 0
  );
});
</script>

<style scoped lang="scss">
.element-border {
  border-bottom: 1px solid $ash-regular;
  border-radius: 4px;
}
</style>
