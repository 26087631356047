<template>
  <v-expansion-panels
    data-testid="hub-profile-employees-container"
    class="shadow-regular"
    :value="open"
    flat
  >
    <v-expansion-panel class="rounded-lg pa-2">
      <v-expansion-panel-header class="heading-h3 mb-2">
        <div class="d-flex align-center">
          <span data-testid="hub-profile-employees-title" class="mr-2">
            {{ t('Title_.subTitle.listOfEmployees') }}
          </span>
          <v-tooltip top color="ashLight">
            <template #activator="{ on, attrs }">
              <v-icon
                data-testid="hub-profile-employees-info-icon"
                color="blueRegular"
                dark
                v-bind="attrs"
                v-on="on"
                small
              >
                mdi-information-outline
              </v-icon>
            </template>
            <div
              data-testid="hub-profile-employees-tooltip"
              class="d-flex flex-column pa-2"
            >
              <span class="tooltip-text body-text-3">
                {{ `${t('Msg_.employeeStatusIncluded')}:` }}
              </span>
              <div class="d-flex align-center">
                <v-icon color="#212121" dark small>
                  mdi-check-circle-outline
                </v-icon>
                <span class="tooltip-text body-text-4 ml-1">{{
                  `${t('Msg_.starter')}, ${t('Msg_.active')}, ${t(
                    'Msg_.inactive'
                  )}, ${t('Msg_.offboarding')}`
                }}</span>
              </div>
              <div class="d-flex align-center">
                <v-icon color="#212121" dark small>
                  mdi-close-circle-outline
                </v-icon>
                <span class="tooltip-text body-text-4 ml-1">
                  {{ t('Msg_.exited') }}
                </span>
              </div>
            </div>
          </v-tooltip>
        </div>
        <template #actions>
          <v-icon
            data-testid="hub-profile-employees-chevron-icon"
            color="#212121"
          >
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-for="(employee, index) in hubEmployees" :key="employee.id">
          <HubProfileEmployeesListItem
            class="pb-2"
            :employee="employee"
            :index="index"
          />
        </div>
        <v-container
          v-if="hubEmployees.length === 0"
          class="pa-0 mt-4 body-text-2"
        >
          <v-row dense data-testid="hub-profile-employees-empty-list-message">
            <v-col>
              {{ t('Msg_.emptyListMessage') }}
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import HubProfileEmployeesListItem from './hub-profile-employees-list-item';

import languages from '../../locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { useRoute } from 'vue-router/composables';
import { useHubsStore } from '@/store/pinia/hubs.store.js';
import { ref } from 'vue';

const route = useRoute();
const hubsStore = useHubsStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const open = ref(0);
const hubEmployees = ref([]);

// --- Methods ---
const getHubEmployeeList = async () => {
  try {
    const res = await hubsStore.getHubEmployeeList(route.params.hubId);
    hubEmployees.value = res.items;
  } catch (error) {
    console.log(error);
  }
};

// --- Lifecycle hooks ---
getHubEmployeeList();
</script>

<style scoped lang="scss">
.tooltip-text {
  color: #212121;
}
</style>
